<script>
  export let name = '';
  export let description = '';
</script>

<div class="card-content">
  <div class="content">
    <div class="name">{name}</div>
    <div class="description">{description}</div>
  </div>
</div>
